
$font-stack:    Helvetica, sans-serif;
$primary-color: #cc6e72;
$secondary-color: rgba(60, 8, 118, 0.8);
$blueish-gray: #aaaaaa;
$light-gray: #faf6fb;
$dark-gray: #666666;
$dark-red: #922126;
$blue-bg: rgba(244,247,249, 1);
$menu-bg-color: $primary-color;
$text-over-primary: #ffffff; //#373a47;
$text-over-primary-accent: #666666;
$text-over-blue-bg: $blueish-gray;
$header-bg-color: white;
$dark-blue-bg: #2B3C4E;

// Colors
$bg-color: #cecece;
$dot-color: #e5e5e5;

$phone-width: 420px;
$tablet-width: 768px;
$desktop-width: 1024px;

$border-radius: 5px;

// Dimensions
$dot-size: 2px;
$dot-space: 22px;

@mixin phone {
  @media (max-width: #{$phone-width}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$phone-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

* {
  box-sizing: border-box;
}
body {
  background-color: #282C32;
  color: #ffffff;
}

.App {
  text-align: center;
}

.egga-logo {
  height: 10vmin;
  pointer-events: none;
}

.app-standby {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.screen {
  width: 100vw;
  min-height: 100vh;
  background-attachment: fixed;
  background-size: cover;
  background-position: center center;
  padding-top: 35px;
}

.fixed {
  transition: 1s;
  position: fixed;
  background-color: #fff;
  width: 100%;
  top: 0;
  box-shadow: 0 1px 5px rgba(0,0,0,.5);
}

.fixed a {
  color: #000!important;
}

.yanone {
  font-family: 'Yanone Kaffeesatz', sans-serif;
}

h1 {
  font-size: 3.7vh;

}

.home-text {
  line-height: 2;
  text-shadow: 1px 1px 0 #000000;
  margin-top: 30px;
  font-size: 2.3vh;
}


.how-it-works { 
  min-height: 100vh;
  padding-top: 8vh;
  background-color: $dark-blue-bg;
  h3 {
    font-size: 3.5vh;
    line-height: 5vh;
    font-weight: normal;
    margin-top: 0;
  }

  .iconBox {
    position: relative;
    height: 140px;
  }
  .step {
    margin-top: 50px;
  }
}


.gradient-background-area {
  background: $primary-color;
  background: -moz-linear-gradient(45deg, $primary-color 0%, $secondary-color 100%);
  background: -webkit-gradient(left bottom, right top, color-stop(0%, $primary-color), color-stop(100%, $secondary-color));
  background: -webkit-linear-gradient(45deg, $primary-color 0%, $secondary-color 100%);
  background: -o-linear-gradient(45deg, $primary-color 0%, $secondary-color 100%);
  background: -ms-linear-gradient(45deg, $primary-color 0%, $secondary-color 100%);
  background: linear-gradient(45deg, $primary-color 0%, $secondary-color 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$green', endColorstr='$light-blue', GradientType=1);
}

.gradient-background-area-2 {
  background-image: -moz-linear-gradient( 135deg, rgba(60, 8, 118, 0.8) 0%, rgba(250, 0, 118, 0.8) 100%);
  background-image: -webkit-linear-gradient( 135deg, rgba(60, 8, 118, 0.8) 0%, rgba(250, 0, 118, 0.8) 100%);
  background-image: -ms-linear-gradient( 135deg, rgba(60, 8, 118, 0.8) 0%, rgba(250, 0, 118, 0.8) 100%);
  background-image: linear-gradient( 135deg, rgba(60, 8, 118, 0.8) 0%, rgba(250, 0, 118, 0.8) 100%);
}

.light-gray-background {
  background-color: $light-gray;
}


.diagonal {
  -ms-transform: rotate(-15deg); /* IE 9 */
  -webkit-transform: rotate(-15deg); /* Safari prior 9.0 */
  transform: rotate(-15deg);
  position: fixed;
  bottom: -15vh;
  right: -25vw;
  height: 50vh;
  width: 150vw;
  z-index: -1;
}

.title {
  font-family: 'Yanone Kaffeesatz', sans-serif;
}

.dark-overlay {
  background-color: rgba(0,0,0,.5);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
}